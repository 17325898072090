<template>
  <div class="about">
    <div class="content">
      <div class="left-content">
        <div class="hero-info pt-sans-regular">
          <h1 class="hero-heading">Since 2010</h1>
          <div class="hero-info-text-box">
            <p class="hero-paragraph">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
          </div>
        </div>
      </div>
      <div class="right-content">

      </div>
    </div>
  </div>

</template>

<style scoped>

.about {
  width: 100%;
  height: 100%;
  background-image: url('../assets/light-page.jpg');
  background-size: cover;
  /* Ensures the image covers the whole area */
  background-position: center;
  backdrop-filter: blur(10px); /* Blur everything behind this element */
  filter: drop-shadow(2px 4px 6px black);


}

/* Overlay for darkening the background */
.about::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4); /* Semi-transparent gray/black overlay */
  z-index: 1; /* Ensures overlay stays behind text */
}

/* Make sure content is on top of the overlay */
.about .content {
  position: relative;
  z-index: 2; /* Positions content above the overlay */
}

.content{
  justify-content: space-between;
}



</style>