<template>
  <div class="home">
    <div class="content">
      <img alt="Francesca" class="logo" src="../assets/logo-text-green.png">
    </div>
    <!-- <HelloWorld msg="Welcome to Your Vue.js App" /> -->
  </div>

</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
    HelloWorld,
  }
}
</script>


<style scoped>
.home {
  width: 100%;
  height: 100%;
  background-image: url('../assets/splash.jpg');
  background-size: cover;
  /* Ensures the image covers the whole area */
  background-position: center;
  backdrop-filter: blur(10px); /* Blur everything behind this element */

}

.splash-image {
  width: 100%;
  height: 100%;
}

.logo {
  display: block;
  width: 40%;
  filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.5)); /* Adds a shadow around the logo */
}

.content{
  justify-content: space-around
}

</style>