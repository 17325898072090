<template>
  <div id="app">
    <!-- NavBar that hovers over the page content -->
    <NavBar />

    <SinglePage></SinglePage>
  </div>
</template>

<script>
// Importing NavBar
import NavBar from './components/NavBar.vue';
import SinglePage from './views/SinglePage.vue'

export default {
  name: 'App',
  components: {
    NavBar,
    SinglePage
  },
};
</script>

<style lang="scss">

// Main Settings

body{
  padding: 0
}

#app {
  font-family: "PT Sans", sans-serif;
  height: 100vh; /* Full viewport height */
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  position: relative; /* For proper stacking of elements */
}


// Fonts

p.hero-paragraph {
  font-size: 1.5rem;
}

h1.hero-heading {
  font-size: 5.5rem;
  color: #e5e5e5; /* Slightly off-white color */

}

.pt-sans-regular {
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.pt-sans-bold {
  font-family: "PT Sans", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.pt-sans-regular-italic {
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.pt-sans-bold-italic {
  font-family: "PT Sans", sans-serif;
  font-weight: 700;
  font-style: italic;
}




</style>
