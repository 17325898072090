<template>
  <div class="main-content">
    <!-- <HomeView class="homeView"></HomeView> -->
    <VideoView videoSrc="../assets/FK.mp4" />
    <AboutView class="aboutView"></AboutView>
  </div>
</template>

<script>
import HomeView from './HomeView.vue';
import AboutView from './AboutView.vue';
import VideoView from './VideoView.vue';

export default {
  name: 'SinglePage',
  data() {
    return {
      currentView: 'home', // Start with HomeView as the default view
    };
  },

  components: {
    HomeView,
    AboutView,
    VideoView
  }

};
</script>

<style>
/* General Styles */

.main-content {
  width: 100%;
  height: 100%;
}



.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;
}

.left-content{
  width: 30%;
  margin-left: 5rem;
}

/* Text Boxes */

.hero-info-text-box{
  margin-top: 4rem;
  line-height: 3.2rem;
  color: #e5e5e5; /* Slightly off-white color */

}

.homeView{
  height: 100% !important;
}


</style>
