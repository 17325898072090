<template>
  <div class="video-container">
    <section v-if="showHome" class="home">
      <div class="content">
        <img alt="Francesca" class="logo" src="../assets/logo-text-green.png">
      </div>
    </section>
    <section v-else class="video-section">
      <video ref="video" src="../assets/FK.mp4" muted @ended="handleVideoEnd" playsinline>
        Your browser does not support the video tag.
      </video>
    </section>
  </div>
</template>

<script>
import { nextTick } from 'vue'; // Import nextTick

export default {
  name: 'VideoView',
  props: {
    videoSrc: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showHome: true // Initially show the home view
    };
  },
  mounted() {
    setTimeout(() => {
      this.showHome = false; // Hide home view and show video after 3 seconds
      nextTick().then(() => {
        this.createObserver(); // Start observing the video after DOM update
      });
    }, 3000); // 3 seconds delay
  },
  methods: {
    createObserver() {
      const videoElement = this.$refs.video;
      if (videoElement) { // Check if video element exists
        const options = {
          root: null, // Use the viewport as the root
          rootMargin: '0px',
          threshold: 0.5 // Play video when 50% of it is visible
        };

        this.observer = new IntersectionObserver(this.handleIntersect, options);
        this.observer.observe(videoElement);
      }
    },
    handleIntersect(entries) {
      const videoElement = this.$refs.video;
      if (videoElement) { // Check if video element exists
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            videoElement.play();
          } else {
            videoElement.pause();
          }
        });
      }
    },
    handleVideoEnd() {
      this.showHome = true; // Show home view after video ends
    }
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }
};
</script>


<style scoped>
.video-container {
  position: relative;
  width: 100%;
  height: 100vh;
}

.home {
  width: 100%;
  height: 100%;
  background-image: url('../assets/splash.jpg');
  background-size: cover;
  background-position: center;
  backdrop-filter: blur(10px); /* Blur everything behind this element */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1; /* Ensure the home view stays above the video initially */
}

.video-section {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0; /* Ensure the video is below the home view initially */
}

video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the video covers the entire area */
  transform: translate(-50%, -50%);
}

.logo {
  display: block;
  width: 40%;
  filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.5)); /* Adds a shadow around the logo */
}

.content{
  justify-content: space-around
}

</style>
