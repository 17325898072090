<template>
  <nav class="navbar navbar-expand-lg translucent-navbar">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">Francesca</a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link class="nav-link active" aria-current="page" to="/">Home</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/about">About</router-link>
          </li>
        </ul>
        <!-- <form class="d-flex" role="search">
          <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
          <button class="btn btn-outline-success" type="submit">Search</button>
        </form> -->
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'NavBar',
};
</script>

<style>
/* Navbar styling */
.translucent-navbar {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: rgba(195, 195, 195, 0.811); /* Translucent background */
  z-index: 1000; /* Ensure it is on top of other elements */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional shadow */
  backdrop-filter: blur(10px); /* Adds a blur effect to the translucent background */
}

.navbar-brand,
.nav-link {
  color: #2c3e50 !important; /* Ensures the links are visible */
}

.navbar-nav .nav-link:hover {
  color: #42b983 !important; /* Change color on hover */
}

/* Add padding to the main content to account for the fixed navbar */
body {
  padding-top: 70px; /* Adjust this value based on the height of the navbar */
}

nav {
  position: absolute;
  top: 0;
  width: 100%;
  opacity: 80%;
  background: rgba(255, 255, 255, 0.3); /* Transparent navbar */
  z-index: 100; /* Ensure it's above the routed content */
  padding: 15px;
  backdrop-filter: blur(10px); /* Optional: add a blur effect to translucent background */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional shadow */
  font-family: "PT Sans", sans-serif;


}

.navbar-brand, .nav-link{
  /* color: #7eae61 !important; */
  font-family: "PT Sans", sans-serif;

}

.form-control input{
  background-color: #ffffffe8;
}

</style>
